import React from 'react';
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image"
import { includes, orderBy } from 'lodash'
import map from 'lodash/map';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

import {
  Container,
  Headline,
  Grid,
  Card,
  ImageContainer,
  ArticleLink,
} from './SimilarArticles.css'

const similarArticlesFilter = (articles, currentArticleSlug, category, tags) => {
  const getCategory = (article) => {
    return article.node.categories[0].name
  }

  const getTags = (article) => {
    const tags = map(article.node.tags, tag => {
      return tag.name
    });

    return tags;
  }

  const remainingArticles = filter(articles, (article) => {
    return article.node.slug !== currentArticleSlug;
  });

  const identityMap = {};

  const addToMap = (article) => {
    if (!identityMap.hasOwnProperty(article.node.slug)) {
      identityMap[article.node.slug] = {
        article: article,
        points: 0
      }
    }
  }

  const addCategoryPoints = (article, category) => {
    const categoryPoints = 2;
    const articleCategory = getCategory(article);

    if (articleCategory === category) {
      identityMap[article.node.slug].points += categoryPoints;
    }
  }

  const addTagsPoints = (article, tags) => {
    if (!tags) {
      return;
    }

    const tagPoint = 1;
    const articleTags = getTags(article);

    articleTags.forEach((aTag) => {
      if (includes(tags, aTag)) {
        identityMap[article.node.slug].points += tagPoint;
      }
    })
  }

  const scoredArticles = forEach(remainingArticles, (article) => {
    addToMap(article);
    addCategoryPoints(article, category);
    addTagsPoints(article, tags);
  });


  const getIdentityMapAsArray = () => {
    return Object.keys(identityMap).map((slug) => identityMap[slug]);
  }


  const arrayIdentityMap = getIdentityMapAsArray();
  const similarArticles = orderBy(arrayIdentityMap, ['points'], ['desc']);
  return similarArticles.splice(0, 3);
}

const ArticleCard = ({node}) => {
  return (
    <Card>
      <ImageContainer>
      <Link to={`/post/${node.slug}`}>
        <Img
          fixed={node.featured_media.localFile.childImageSharp.fixed}
          alt={node.title}
        />
      </Link>
      </ImageContainer>
      <p>
        <ArticleLink
          to={`/post/${node.slug}`}
          dangerouslySetInnerHTML={{ __html: node.title }}
        />
      </p>
    </Card>
  );
}

const SimilarArticlesComponent = ({ articles }) => {
  return (
    <Container>
      <Headline>Related posts</Headline>
      <Grid>
      {articles.map((article, i) => (
        <ArticleCard {...article.article} key={i}/>
      ))}
      </Grid>
    </Container>
  );
}

const query = graphql`
  query SimilarArticles {
    posts: allWordpressPost(
      sort: { order: DESC, fields: [date] }
      filter: {categories: {elemMatch: {slug: {eq: "news"}}}}
      limit: 1000
    ) {
      edges {
        node {
          title
          excerpt
          slug
          author {
            name
          }
          categories {
            name
          }
          tags {
            name
          }
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
              url
            }
          }
        }
      }
    }
  }
`;

const SimilarArticles = ({ category, tags, currentArticleSlug }) => {
  if (!tags) {
    return null;
  }

  return (
    <StaticQuery
      query={query}
      render={data => {
        const articles = data.posts.edges;
        const related = similarArticlesFilter(articles, currentArticleSlug, category, tags);

        return (
          <SimilarArticlesComponent articles={related} />
        );
      }}
    />
  );
}

export default SimilarArticles;
