import React from "react"
import { graphql, Link } from "gatsby"
import map from 'lodash/map';
import he from 'he';

import Layout from "../../components/Layout/Layout";
import SimilarArticles from "../../components/SimilarArticles/SimilarArticles";
import SEO from "../../components/seo"

import {
  CategoryName,
  CategoryNameContainer,
  ImageContainer,
  Image,
  BlogPostHeadline,
  Post,
  Paginate,
  PostMeta,
  ReadNext,
} from './BlogPost.css';

const BlogPost = ({ data, pageContext }) => {
  const getCategory = () => {
    return data.wordpressPost.categories[0].name
  }

  const getTags = () => {
    if (!data.wordpressPost.tags) {
      return null;
    }

    const tags = map(data.wordpressPost.tags, tag => {
      return tag.name
    });

    return tags;
  }

  const next = () => {
    if (!pageContext.next) {
      return null;
    }

    return (
      <div>
        <Link to={`/post/${pageContext.next.slug}`}>
          {he.decode(pageContext.next.title)}
        </Link>
      </div>
    );
  }

  const previous = () => {
    if (!pageContext.previous) {
      return null;
    }

    return (
      <div>
        <Link to={`/post/${pageContext.previous.slug}`}>
          {he.decode(pageContext.previous.title)}
        </Link>
      </div>
    );
  }

  const ogImage = data.wordpressPost.featured_media.localFile.childImageSharp.fluid;
  const ogImageSize = data.wordpressPost.featured_media.media_details;

  return (
    <Layout page="blog">
      <SEO
        title={he.decode(data.wordpressPost.title)}
        description={data.wordpressPost.excerpt}
        ogImage={ogImage}
        ogImageSize={ogImageSize}
      />
      <CategoryNameContainer>
        <CategoryName>{getCategory()}</CategoryName>
      </CategoryNameContainer>
      <BlogPostHeadline dangerouslySetInnerHTML={{ __html: data.wordpressPost.title }} />
      <ImageContainer>
        <Image
          fluid={data.wordpressPost.featured_media.localFile.childImageSharp.fluid}
          alt={data.wordpressPost.title}
        />
      </ImageContainer>
      <PostMeta>
        Written by {data.wordpressPost.author.name} on {data.wordpressPost.date}
      </PostMeta>
      <Post
        dangerouslySetInnerHTML={{
          __html: data.wordpressPost.content
          .replace(/width/g, '')
          .replace(/height/g, '')
        }}
      />
      <ReadNext>Read next:</ReadNext>
      <Paginate>
        {previous()}
        {next()}
      </Paginate>
      <SimilarArticles
        category={getCategory()}
        tags={getTags()}
        currentArticleSlug={data.wordpressPost.slug}
      />
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      content
      excerpt
      categories {
        name
      }
      tags {
        name
      }
      slug
      date(formatString: "MMMM DD, YYYY")
      author {
        name
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
              originalImg
            }
          }
          url
        }
        media_details {
          height
          width
        }
      }
    }
  }
`
