import styled from 'styled-components'
import Img from "gatsby-image"

import { media } from '../../styles/mixins.css';

export const CategoryNameContainer = styled.div`
  margin-top: 1rem;
  display: flex
`;

export const CategoryName = styled.span`
  color: white;
  font-weight: 100;
  text-transform: uppercase;
  background: #d64c4c;
  padding: 0.25rem 0.5rem;
`;

export const BlogPostHeadline = styled.h1`
  text-align: left;
  font-family: Roboto;
  font-size: 2rem;
  line-height: 1.2;
  margin: 1rem 0 2rem 0;
  letter-spacing: -1px;
  /* background: -webkit-linear-gradient(top, #3F51B5,#03A9F4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

  @media ${media.medium} {
    font-size: 3.5rem;
    font-weight: 600;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center

  .gatsby-image-wrapper {
    width: 300px;

    @media ${media.medium} {
      width: 700px;
    }
  }
`;

export const Image = styled(Img)`
  width: 300px; // or set a specific size and it'll be centered within the available space of the flex parent.

  @media ${media.medium} {
    width: 700px;
  }
`;

export const PostMeta = styled.p`
  color: gray;
  border-left: 8px solid #d64c4c;
  padding-left: 0.5rem;
`;

export const ReadNext = styled.h4`
  font-size: 1rem;
  border-left: 8px solid #d64c4c;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 3rem;
`;

export const Paginate = styled.div`
  display: flex;

  div {
    background: white;
    width: 100%;

    @media ${media.medium} {
      flex-shrink: 0;
      width: 50%;
    }
  }

  a {
    display: block;
    text-decoration: none;
    color: black;
    transition: 0.1s;
    font-size: 1.25rem;
    line-height: 1.2;
    text-align: center;
    padding: 1rem;

    &:hover, &:focus {
      color: #03a9f4;
    }

  }
`;

export const Post = styled.div`
  font-size: 1.125rem;

  p {
    line-height: 1.5;
    margin: 2rem 0;

    &:first-child {
      font-size: 1.6rem;
    }
  }

  blockquote {
    p:first-child {
      font-size: 1.125rem;
    }
  }

  .wp-block-embed__wrapper {
    overflow: hidden;
    padding-top: 56.25%; /* 16:9*/
    position: relative;

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .wp-block-image {
    margin: 0 auto;
    text-align: center;
  }
`;
