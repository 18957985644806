import styled from "styled-components";
import { Link } from "gatsby";

import { HeadlineLink } from '../../styles/typography.css';

export const Container = styled.aside`
  margin: 5rem 0;
`;

export const Headline = styled.h4`
  margin-bottom: 1rem;
  font-family: Roboto;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 1.2;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
`;

export const Card = styled.div`
  min-width: 0;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 8rem;
  overflow: hidden;
  position: relative;

  .gatsby-image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ArticleLink = styled(Link)`
  ${HeadlineLink}
`
